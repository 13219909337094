<template>
  <div v-if="loading" style="padding-top: 32px;">
    <div class="setting-block-statistics">
      <div class="statistics-block">
        <div class="statistics-block-title">Всего пользователей</div>
        <div class="statistics-block-value"> {{ item.assignmentCount ? item.assignmentCount : 0 }}</div>
      </div>
      <div class="statistics-block">
        <div class="statistics-block-title">Всего прохождений</div>
        <div class="statistics-block-value">
          {{ item.totalAttempts ? item.totalAttempts : 0}}
        </div>
      </div>
      <div class="statistics-block">
        <div class="statistics-block-title">Средний результат</div>
        <div class="statistics-block-value" v-if="item.averagePercent">
          {{ item.averagePercent }} %
        </div>
        <div class="statistics-block-value" v-else>0 %</div>
      </div>
    </div>
    <div class="block-items-setting">
      <div class="setting-block">
        <div class="setting-block-box">
          <div class="block-box-title">
            <div class="box-title-text">Основное</div>
          </div>
          <TextInput label="Название" v-model="test.name" :valid="true" />
          <ImageInput label="Обложка" v-model="test.photo" />
          <TextArea label="Описание" v-model="test.description" />

          <SwitchInput
            icon="mdi-eye-outline"
            label="Видимость"
            v-model="test.isVisible"
          />
          <SwitchInput
            icon="mdi-text-box-outline"
            label="Итоговый тест"
            v-model="test.isImportantTest"
          />
          <SwitchInput
            icon="mdi-chart-bar"
            label="Сбор статистики"
            v-model="test.toCollectStatistics"
          />
          <TextInput
            label="Проходной процент"
            v-model="test.passPercent"
            :valid="true"
            type="number"
            :max="100"
            prependIcon="mdi-percent-outline"
          />
          <div class="block-box-title">
            <div class="box-title-text">Попытки</div>
          </div>
          <SwitchInput
            icon="mdi-all-inclusive"
            label="Без ограничения повторных попыток"
            v-model="infinity"
          />
          <TextInput
            v-if="!infinity"
            label="Количество попыток"
            v-model="test.tryingCount"
            :valid="true"
            type="number"
            prependIcon="mdi-replay"
          />
          <div class="block-box-title">
            <div class="box-title-text">Результаты</div>
          </div>
          <SelectInput
            itemValue="type"
            itemText="text"
            label="Отображение отчета о результатах после завершения"
            v-model="test.resultReportType"
            :items="resultReportTypes"
            :clearable="false"
          />
          <SwitchInput
            icon="mdi-comment-check-outline"
            label="Показывать правильность после ответа"
            v-model="test.toShowResult"
          />
          <SwitchInput
            icon="mdi-comment-remove-outline"
            label="Показывать верный вариант при ошибке после ответа"
            v-model="test.toShowAnswer"
          />

          <div class="block-box-title">
            <div class="box-title-text">Вопросы</div>
          </div>
          <SwitchInput
            icon="mdi-priority-low"
            label="Отложить вопрос на потом"
            v-model="test.isPossibleMiss"
          />
          <SwitchInput
            icon="mdi-shuffle"
            label="В случайном порядке"
            v-model="test.isRandomQuestionsOrder"
          />
          <TextInput
            label="Количество вопросов в одном тесте для ученика"
            v-model="test.questionsCountLimit"
            type="number"
            prependIcon="mdi-format-line-spacing"
          />
          <div class="block-box-title">
            <div class="box-title-text">Время</div>
          </div>
          <SelectInput
            itemValue="type"
            itemText="text"
            label="Отображение отчета после завершения"
            v-model="test.timeLimitType"
            :items="limitTypes"
            @change="changeSelect"
          />
          <TextInput
            v-if="test.timeLimitType != 'unlimited'"
            :label="timeLimitLabel"
            v-model="timeLimit"
            type="number"
            prependIcon="mdi-timer"
          />
        </div>
      </div>
      <div class="setting-block">
        <div class="setting-block-box">
          <div class="block-box-title">
            <div class="box-title-text">Данные</div>
          </div>
          <div class="block-box-items">
            <div class="box-items-info">
              <div class="info-title">ID Теста</div>
              <div class="info-value">
                {{ test.id }}
              </div>
            </div>
            <div class="box-items-info">
              <div class="info-title">Дата создания</div>
              <div class="info-value">
                {{ test.dateCreated | moment("DD.MM.YYYY") }}
              </div>
            </div>
            <div class="box-items-info">
              <div class="info-title">Дата последнего редактирования</div>
              <div class="info-value">
                {{ test.dateUpdated | moment("DD.MM.YYYY") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },

  },
  data: () => ({
    test: {},
    infinity: false,
    deleteDialog: false,
    timeLimit: 0,
    limitTypes: [
      { text: "Ограничение на прохождения теста", type: "test" },
      { text: "Ограничение на прохождения вопроса", type: "question" },
      { text: "Без ограничение", type: "unlimited" },
    ],
    resultReportTypes: [
      { text: "Показывать только правильность", type: "show_results" },
      {
        text: "Показывать ответы ученика и правильные ответы",
        type: "show_results_and_answers",
      },
      {
        text: "Не показывать правильность ответов на вопросы",
        type: "not_show_results",
      },
    ],
    send: false,
  }),
  methods: {
    changeSelect() {
      this.timeLimit = 0;
    },
    resetTest() {
      this.test = Object.assign({}, this.$parent.$data.testObj);
    },
    sendItem() {
      if (this.test.timeLimitType == "question") {
        this.test.timeLimit = this.timeLimit * 1000;
      }
      if (this.test.timeLimitType == "test") {
        this.test.timeLimit = this.timeLimit * 60000;
      }
      return this.test;
    },
    updateTest() {
      this.test = JSON.parse(JSON.stringify(this.item));
      this.infinity = this.test.tryingCount ? false : true;
      if (this.test.timeLimitType == "question") {
        this.timeLimit = this.test.timeLimit / 1000;
      }
      if (this.test.timeLimitType == "test") {
        this.timeLimit = this.test.timeLimit / 60000;
      }
      this.loading = true;
    },
  },
  created() {
    this.updateTest();
  },
  computed: {
    timeLimitLabel() {
      if (this.test.timeLimitType == "test") {
        return "Время на тест (в минутах)";
      } else if (this.test.timeLimitType == "question") {
        return "Время на один вопрос (в секундах)";
      } else {
        return "";
      }
    },
  },


  watch: {
    infinity() {
      this.infinity == false ? "" : (this.test.tryingCount = null);
    },
    item() {
      this.updateTest();
    },
  },
};
</script>
<style lang="scss" scoped>
.setting-block-statistics {
  display: flex;
  justify-content: space-between;
  padding: 0 64px 32px 64px; 

  .statistics-block {
    padding: 24px;
    background: var(--app-white);
    border-radius: 6px;
    margin: 0px 8px;
    width: 100%;
    // min-width: 385px;
    .statistics-block-title {
      font-size: 18px;
      font-weight: 600;
      color: var(--app-black);
      margin-bottom: 4px;
    }
    .statistics-block-value {
      font-size: 16px;
      color: var(--app-grey-3);
    }
  }
  .statistics-block:first-child {
    margin-left: 0px;
  }
  .statistics-block:last-child {
    margin-right: 0px;
  }
}
.block-items-setting {
  height: 100%;
  padding: 8px 64px;
  display: flex;
  justify-content: space-between;
  .setting-block {
    .setting-block-box {
      .block-box-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .box-title-text {
          font-size: 22px;
          font-weight: 600;
          color: var(--app-black);
        }
      }
      .block-box-items {
        .box-items-info {
          margin-bottom: 24px;
          .info-title {
            font-size: 12px;
            text-transform: uppercase;
            color: var(--app-grey-3);
          }
          .info-value {
            font-size: 14px;
            color: var(--app-black);
          }
        }
      }
    }
  }
  .setting-block:first-child {
    width: 60%;
    padding-right: 48px;
  }
  .setting-block:last-child {
    width: 40%;
    padding-left: 48px;
  }
}
</style>