<template>
  <div class="page-container" v-if="loading">
    <div
      class="container-main-block"
      :style="tab === 0 ? `height: calc(100vh - 64px);` : 'height: 100vh'"
    >
      <div class="main-block-title pl-16 pr-16">
        {{ item.name }}
      </div>
      <v-tabs
        class="pl-16 pr-16"
        v-model="tab"
        background-color="var(--app-grey-1)"
        color="var(--app-blue)"
      >
        <v-tabs-slider color="var(--app-blue)"></v-tabs-slider>
        <v-tab>
          <v-icon class="mr-2" size="18">mdi-information</v-icon>
          <div class="tab-text">Информация</div>
        </v-tab>
        <v-tab>
          <v-icon class="mr-2" size="18">mdi-view-split-vertical</v-icon>
          <div class="tab-text">Содержание</div>
        </v-tab>
        <v-tab disabled>
          <v-icon class="mr-2" size="18">mdi-file-tree</v-icon>
          <div class="tab-text">Привязка</div>
        </v-tab>
      </v-tabs>
      <v-divider style="margin: 0 64px"></v-divider>
      <div class="main-block-items">
        <v-tabs-items v-model="tab">
          <v-tab-item :value="0">
            <TestInformation
              ref="testInformation"
              :item="item"
            />
          </v-tab-item>
          <v-tab-item :value="1"> <TestQuestions /> </v-tab-item>
          <v-tab-item :value="2"> 3 </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
    <div class="container-bottom-block" v-if="tab === 0">
      <div>
        <v-btn depressed @click="getItem" class="button-white-black mr-4">
          Отменить изменения
        </v-btn>
        <v-btn depressed class="button-blue" @click="sendItem">
          Cохранить изменения
        </v-btn>
      </div>
      <div>
        <v-btn depressed class="button-white-red" @click="dialogDelete = true">
          Удалить тест
        </v-btn>
      </div>
    </div>

    <DialogConfirm
      v-model="dialogDelete"
      titleText="Удаление теста"
      :mainText="`Вы уверены что хотите удалить  ${
        item ? `${item.name}` : 'тест'
      }?`"
      @click:delete="deleteItem"
    >
    </DialogConfirm>
  </div>
</template>
<script>
import DialogConfirm from "@/components/main/dialog/DialogConfirm";
import TestInformation from "@/views/Tests/TestInformation";
import TestQuestions from "@/views/Tests/TestQuestions";

export default {
  components: {
    DialogConfirm,
    TestInformation,
    TestQuestions,
  },
  data: () => ({
    item: {},
    loading: false,
    tab: 0,
    dialogDelete: false,
  }),
  methods: {
    async getItem() {
      await this.$axios
        .get(this.$store.getters.apiAdminPanelV4 + "/test", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          this.item = res.data;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    getAllValues() {
      Promise.all([this.getItem()]).then(() => {
        this.loading = true;
      });
    },
    sendItem() {
      const item = this.$refs.testInformation.sendItem();
      if (item) {
        let formData = new FormData();
        formData.append("id", item.id);
        formData.append("name", item.name);
        formData.append("description", item.description || "");
        formData.append("isImportantTest", item.isImportantTest);
        formData.append("isPossibleMiss", item.isPossibleMiss);
        formData.append("isVisible", item.isVisible);
        formData.append("photo", item.photo);
        if (item.questionsCountLimit) {
          formData.append("questionsCountLimit", item.questionsCountLimit);
        }
        formData.append("toCollectStatistics", item.toCollectStatistics);
        formData.append("timeLimitType", item.timeLimitType);
        if (item.timeLimit) {
          formData.append("timeLimit", item.timeLimit);
        }
        if (item.tryingCount) {
          formData.append("tryingCount", item.tryingCount);
        }
        formData.append("isRandomQuestionsOrder", item.isRandomQuestionsOrder);
        formData.append("passPercent", item.passPercent);
        formData.append("toShowResult", item.toShowResult);
        formData.append("toShowAnswer", item.toShowAnswer);
        formData.append("resultReportType", item.resultReportType);

        this.$axios
          .post(this.$store.getters.apiAdminPanelV4 + "/test", formData, {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          })
          .then(() => {
            this.$store.commit("successSet", true);
            this.$store.commit(
              "successTextSet",
              "Измененения успешно сохранены"
            );
            this.getAllValues();
          })
          .catch((err) => {
            this.$store.commit("errorSet", true);
            this.$store.commit("errorTextSet", err);
          });
      }
    },
    deleteItem() {
      this.$axios
        .delete(this.$store.getters.apiAdminPanelV4 + "/test", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: this.$route.params.id,
          },
        })
        .then(() => {
          this.$router.push({ name: "Tests" });
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
  },
  created() {
    this.getAllValues();
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;

  .container-main-block {
    display: flex;
    flex-direction: column;

    .main-block-title {
      margin-top: 40px;
      margin-bottom: 20px;
      font-size: 28px;
      font-weight: 600;
      color: var(--app-black);
    }
    .main-block-items {
      height: 100%;
      overflow: auto;
    }
  }
  .container-bottom-block {
    height: 64px;
    background: var(--app-grey-2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 64px;
  }
}
</style>