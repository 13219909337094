var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',{staticClass:"test-questions-block"},[_c('div',{staticClass:"questions-block-title"},[_c('v-btn',{staticClass:"button-white-blue",attrs:{"depressed":""},on:{"click":_vm.openDialog}},[_vm._v(" Добавить вопрос ")])],1),_c('div',{staticStyle:{"height":"calc(100vh - 285px)"}},[_c('Table',{attrs:{"items":_vm.items,"headers":_vm.headers,"headerFixed":"","draggable":""},on:{"click:row":_vm.clickItem,"click:draggable":_vm.newSort},scopedSlots:_vm._u([{key:"item.sort",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.countOfAttempts",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.statistic && item.statistic.countOfAttempts ? item.statistic.countOfAttempts : "0")+" ")]}},{key:"item.correctAnswers",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.statistic && item.statistic.correctAnswers ? item.statistic.correctAnswers : "0")+" ("+_vm._s(item.statistic && item.statistic.correctAnswersPercent ? item.statistic.correctAnswersPercent : "0")+"%) ")]}},{key:"item.wrongAnswers",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.statistic && item.statistic.wrongAnswers ? item.statistic.wrongAnswers : "0")+" ("+_vm._s(item.statistic && item.statistic.wrongAnswersPercent ? item.statistic.wrongAnswersPercent : "0")+"%) ")]}}],null,true)})],1),_c('Dialog',{attrs:{"titleText":this.question.id ? 'Редактирование вопроса' : 'Новый вопрос',"refForm":'form',"mainItems":[
      {
        type: 'text',
        label: 'Вопрос',
        value: this.question.text,
        vmodel: function (e) { return (this$1.question.text = e); },
        valid: true,
      },
      {
        type: 'image',
        label: 'Изображение',
        value: this.question.photo,
        vmodel: function (e) { return (this$1.question.photo = e); },
      },
      {
        slotName: 'answers',
      } ],"buttonsFooter":{
      success: {
        title: this.question.id ? 'Сохранить изменения' : 'Добавить вопрос',
        loading: true,
      },
      delete: {
        title: this.question.id ? 'Удалить вопрос' : '',
      },
    }},on:{"click:success":_vm.addQuestion,"click:delete":_vm.deleteQuestion},scopedSlots:_vm._u([{key:"answers",fn:function(){return [_c('div',{staticClass:"dialog-items-answers"},[_vm._v("Варианты ответов")]),_c('div',{staticClass:"dialog-items-answers-objects"},[_c('transition-group',{attrs:{"name":"fade"}},_vm._l((_vm.answers),function(i){return _c('div',{key:i.uniqId,staticClass:"answers-objects-item"},[_c('CheckBox',{staticClass:"objects-item-checkbox",attrs:{"color":"var(--app-blue)"},model:{value:(i.isCorrect),callback:function ($$v) {_vm.$set(i, "isCorrect", $$v)},expression:"i.isCorrect"}}),_c('TextInput',{ref:i.uniqId,refInFor:true,attrs:{"placeholder":"Введите вариант ответа","valid":""},model:{value:(i.text),callback:function ($$v) {_vm.$set(i, "text", $$v)},expression:"i.text"}}),(_vm.answers.length > 0)?_c('div',{staticClass:"objects-item-delete",on:{"click":function($event){return _vm.deleteAnswers(i.uniqId)}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1):_vm._e()],1)}),0),_c('div',{staticClass:"d-flex"},[(_vm.answers.length >= 1)?_c('div',{staticStyle:{"width":"32px"}}):_vm._e(),_c('TextInput',{attrs:{"placeholder":"Добавить ещё один вариант ответа"},model:{value:(_vm.textInput),callback:function ($$v) {_vm.textInput=$$v},expression:"textInput"}})],1)],1)]},proxy:true}],null,false,264593462),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }