<template>
  <div class="test-questions-block" v-if="loading">
    <div class="questions-block-title">
      <v-btn class="button-white-blue" depressed @click="openDialog">
        Добавить вопрос
      </v-btn>
    </div>
    <div style="height: calc(100vh - 285px)">
      <Table
        :items="items"
        :headers="headers"
        headerFixed
        @click:row="clickItem"
        draggable
        @click:draggable="newSort"
      >
        <template v-slot:[`item.sort`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.countOfAttempts`]="{ item }">
          {{
            item.statistic && item.statistic.countOfAttempts
              ? item.statistic.countOfAttempts
              : "0"
          }}
        </template>
        <template v-slot:[`item.correctAnswers`]="{ item }">
          {{
            item.statistic && item.statistic.correctAnswers
              ? item.statistic.correctAnswers
              : "0"
          }}
          ({{
            item.statistic && item.statistic.correctAnswersPercent
              ? item.statistic.correctAnswersPercent
              : "0"
          }}%)
        </template>
        <template v-slot:[`item.wrongAnswers`]="{ item }">
          {{
            item.statistic && item.statistic.wrongAnswers
              ? item.statistic.wrongAnswers
              : "0"
          }}
          ({{
            item.statistic && item.statistic.wrongAnswersPercent
              ? item.statistic.wrongAnswersPercent
              : "0"
          }}%)
        </template>
      </Table>
    </div>
    <Dialog
      v-model="dialog"
      :titleText="this.question.id ? 'Редактирование вопроса' : 'Новый вопрос'"
      :refForm="'form'"
      :mainItems="[
        {
          type: 'text',
          label: 'Вопрос',
          value: this.question.text,
          vmodel: (e) => (this.question.text = e),
          valid: true,
        },
        {
          type: 'image',
          label: 'Изображение',
          value: this.question.photo,
          vmodel: (e) => (this.question.photo = e),
        },
        {
          slotName: 'answers',
        },
      ]"
      @click:success="addQuestion"
      @click:delete="deleteQuestion"
      :buttonsFooter="{
        success: {
          title: this.question.id ? 'Сохранить изменения' : 'Добавить вопрос',
          loading: true,
        },
        delete: {
          title: this.question.id ? 'Удалить вопрос' : '',
        },
      }"
    >
      <template v-slot:answers>
        <div class="dialog-items-answers">Варианты ответов</div>
        <div class="dialog-items-answers-objects">
          <transition-group name="fade">
            <div
              v-for="i in answers"
              :key="i.uniqId"
              class="answers-objects-item"
            >
              <CheckBox
                class="objects-item-checkbox"
                v-model="i.isCorrect"
                color="var(--app-blue)"
              ></CheckBox>

              <TextInput
                v-model="i.text"
                placeholder="Введите вариант ответа"
                valid
                :ref="i.uniqId"
              />
              <div
                class="objects-item-delete"
                v-if="answers.length > 0"
                @click="deleteAnswers(i.uniqId)"
              >
                <v-icon> mdi-close </v-icon>
              </div>
            </div>
          </transition-group>
          <div class="d-flex">
            <div style="width: 32px" v-if="answers.length >= 1"></div>
            <TextInput
              v-model="textInput"
              placeholder="Добавить ещё один вариант ответа"
            />
          </div>
        </div>
      </template>
    </Dialog>
  </div>
</template>
<script>
import TextInput from "@/components/main/inputs/TextInput";
import CheckBox from "@/components/main/inputs/CheckBox";
export default {
  components: {
    TextInput,
    CheckBox,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: () => ({
    loading: false,
    items: [],
    infoPhoto: "",
    dialog: false,
    textInput: "",
    question: {},
    answers: [
      {
        text: "",
        isCorrect: false,
        uniqId: Math.floor(Math.random() * 10000),
      },
    ],
    headers: [
      {
        text: "#",
        value: "sort",
      },
      {
        text: "Вопрос",
        value: "text",
      },
      {
        text: "Показов",
        value: "countOfAttempts",
      },
      {
        text: "Правильные ответы",
        value: "correctAnswers",
      },
      {
        text: " Неправильные ответы",
        value: "wrongAnswers",
      },
    ],
  }),
  methods: {
    clickItem(item) {
      this.question = JSON.parse(JSON.stringify(item));
      this.answers = JSON.parse(JSON.stringify(item.answers));
      this.answers.map((i) => {
        if (!i.uniqId) {
          i.uniqId = Math.floor(Math.random() * 10000);
        }
      });
      this.dialog = true;
    },
    openDialog() {
      this.question = {};
      this.dialog = true;
      this.answers = [
        {
          text: "",
          isCorrect: false,
          uniqId: Math.floor(Math.random() * 10000),
        },
      ];
    },
    deleteAnswers(uniqId) {
      this.answers = this.answers.filter((i) => i.uniqId != uniqId);
    },
    getImageSize(image) {
      return new Promise((resolve, reject) => {
        try {
          let img = new Image();
          img.onload = () => {
            resolve({ infoPhoto: { width: img.width, height: img.height } });
          };
          img.src = URL.createObjectURL(image);
        } catch (e) {
          reject(e);
        }
      });
    },
    addQuestion() {
      if (this.question.photo && typeof this.question.photo != "string") {
        let imageJson = null;
        this.getImageSize(this.question.photo).then((imageInfo) => {
          imageJson = JSON.stringify(imageInfo);
          const newArr = this.answers.filter((i) => {
            if (i.text) {
              delete i.uniqId;
              return i;
            }
          });
          let formData = new FormData();
          if (this.question.id) {
            formData.append("id", this.question.id);
          }

          formData.append("photo", this.question.photo);
          if (imageJson) {
            formData.append("infoPhoto", imageJson);
          }

          formData.append("testId", this.$route.params.id);
          formData.append("text", this.question.text);
          formData.append("answers", JSON.stringify(newArr));
          this.$axios
            .post(
              this.$store.getters.apiAdminPanelV4 + "/test/question",
              formData,
              {
                headers: {
                  authorization: this.$store.getters.adminPanelHeaderAuth,
                },
              }
            )
            .then(() => {
              this.dialog = false;
              this.$nextTick(() => {
                this.getItems();
              });
            })
            .catch((err) => {
              this.$store.commit("errorSet", true);
              this.$store.commit("errorTextSet", err);
            });
        });
      } else {
        const newArr = this.answers.filter((i) => {
          if (i.text) {
            delete i.uniqId;
            return i;
          }
        });
        let formData = new FormData();
        if (this.question.id) {
          formData.append("id", this.question.id);
        }

        formData.append("photo", this.question.photo);
        formData.append("infoPhoto", null);
        formData.append("testId", this.$route.params.id);
        formData.append("text", this.question.text);
        formData.append("answers", JSON.stringify(newArr));
        this.$axios
          .post(
            this.$store.getters.apiAdminPanelV4 + "/test/question",
            formData,
            {
              headers: {
                authorization: this.$store.getters.adminPanelHeaderAuth,
              },
            }
          )
          .then(() => {
            this.dialog = false;
            this.$nextTick(() => {
              this.getItems();
            });
          })
          .catch((err) => {
            this.$store.commit("errorSet", true);
            this.$store.commit("errorTextSet", err);
          });
      }
    },
    deleteQuestion() {
      this.$axios
        .delete(this.$store.getters.apiAdminPanelV4 + "/test/question", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: this.question.id,
          },
        })
        .then(() => {
          this.dialog = false;
          this.$nextTick(() => {
            this.getItems();
          });
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    newSort(value, item) {
      this.$axios
        .put(
          this.$store.getters.apiAdminPanelV4 + "/test/question/sort",
          {
            id: item.id,
            sort: value,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then(() => {})
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    getItems() {
      this.$axios
        .post(
          this.$store.getters.apiAdminPanelV4 + "/test/question/list",
          {
            testId: this.$route.params.id,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.items = res.data
          this.loading = true;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
  },
  created() {
    this.getItems();
  },
  computed: {},
  mounted() {},

  watch: {
    textInput() {
      if (this.textInput && this.textInput.length > 0) {
        const newObj = {
          text: this.text,
          isCorrect: false,
          uniqId: Math.floor(Math.random() * 10000),
        };
        this.answers.push(newObj);
        this.$nextTick(() => {
          this.$refs[newObj.uniqId][0].$refs.input.focus();
          this.textInput = "";
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.test-questions-block {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--app-grey-1);
  padding: 32px 64px;
  .questions-block-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
  }
}

.dialog-items-answers {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 12px;
  color: #767676;
}
.dialog-items-answers-objects {
  .answers-objects-item {
    display: flex;
    .objects-item-checkbox {
      margin-top: 14px;
      margin-right: 16px;
    }
  }
  .objects-item-delete {
    padding-top: 10px;
    margin-left: 10px;
    cursor: pointer;
  }
}
.fade-enter-active {
  animation: bounce-in 0.5s;
}
.fade-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>